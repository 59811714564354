<footer
  class="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-2 border-top small">
<p *ngIf="info" class="text-muted align-text-bottom">
  {{info.version}}
</p>
  <p class="text-muted ">
    Copyright © 2023
    Expaq Creations
  </p>



  <p class="text-muted align-text-bottom">Powered by Expaq</p>
</footer>