import { Component, OnInit } from '@angular/core';
import { BaseModal } from '../base-modal';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-box',
  templateUrl: './confirm-box.component.html',
  styleUrls: ['./confirm-box.component.css']
})
export class ConfirmBoxComponent  extends BaseModal  implements OnInit {
 messageText!: string;
  constructor(
     activeModal: NgbActiveModal    
  ) {
    super(activeModal);
  }


  invalid(contol: Object): boolean {
    return true;
 }
  ok() {
    this.subject.next(true);
    this.subject.complete();
    this.activeModal.close(true);
  }
 cancel() {
    this.subject.next(false);
    this.subject.complete();
    this.activeModal.dismiss();
  }

}
