import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor() {}

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
      if (control instanceof FormArray) {
        for (const groupControl of control.controls) {
          if (groupControl instanceof FormControl) {
            groupControl.markAsTouched({
              onlySelf: true
            });
          }
          if (groupControl instanceof FormGroup) {
            this.validateAllFormFields(groupControl);
          }
        }
        // control.markAsTouched();
      }
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  clearError() {
    // this.error = '';
  }
  validateForm(formGroup: FormGroup): boolean {
    this.validateAllFormFields(formGroup);
    if (formGroup.valid) {
      return true;
    } else {
      // this.findInvalidControls(formGroup);
      return false;
    }
  }
  findInvalidControls(formGroup: FormGroup) {
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log(name);
        const control = controls[name];
        if (control instanceof FormControl) {
          // console.log(`invalid control: ${name},errors: ${controls[name].errors}`);
          const errors = controls[name].errors;
          // Object.keys(errors).forEach(keyError => {
          //   console.log(`invalid control: ${name},error: ${keyError}`);
          // });
        }
      }
    }
  }
  invalid(contol: Object): boolean {
    const ctrl = <FormControl>contol;
    if (contol) {
      return ctrl.invalid && (ctrl.touched || ctrl.dirty);
    }
    return false;
  }
}
