import { OnInit, OnDestroy, Directive } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseModal implements OnInit, OnDestroy {
    protected subject: Subject<any>;
    public result: Observable<any>;
    constructor(     
      public activeModal: NgbActiveModal
    ) {
      console.log('constructor');
      this.subject = new Subject<any>();
      this.result = this.subject.asObservable();
    }

    ngOnInit() {
    console.log('ngOnInit');
    }
    ngOnDestroy(): void {
      console.log('ngOnDestroy');
      this.subject.unsubscribe();
    }
    submit(data: any) {
      this.subject.next(data);
      this.subject.complete();
      this.activeModal.close(data);
    }
    close() {
      // this.subject.complete();
      this.activeModal.dismiss();
    }
    abstract invalid(contol: Object): boolean;

}
