import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInfo } from '../models/app-info.model';
import { MenuItem } from '../models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

  constructor(private http: HttpClient,  @Inject('BASE_URL') private baseUrl: string) { }
  getInfo():Observable<AppInfo> {
    return this.http.get<AppInfo>(`${this.baseUrl}api/v1/Resources/info`);
  }
}
