import {
  Directive,
  ElementRef,
  AfterViewInit,
  Output,
  EventEmitter,
  Renderer2,
  OnChanges,
  SimpleChanges,
  OnInit,
  forwardRef,
  Input,
} from '@angular/core';
import * as AutoNumeric from "autonumeric"

//import AutoNumeric from 'autonumeric/src/main';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export const AUTONUMERIC_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NumberInputDirective),
  multi: true
};
@Directive({
  selector: '[appNumberInput]',
  providers: [AUTONUMERIC_CONTROL_VALUE_ACCESSOR],
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(blur)': 'onTouchedFn()'
  },
})
export class NumberInputDirective implements AfterViewInit, OnChanges, OnInit, ControlValueAccessor {
  @Input() options:any = { decimalPlaces: 2 };
  instance: any;
  private isDisabled = false;
  private initialValue: any;
  @Output()
  formatted = new EventEmitter();
  @Output()
  rawValueModified = new EventEmitter();
  unsubscribeFormat: () => void;
  unsubscribeRawValueModified: () => void;

  onChangeFn: (value: any) => void = () => {
    console.log('onChangeFn');
  }
  onTouchedFn = () => {
  }
  constructor(
    private el: ElementRef,
    private renderer: Renderer2) { }

  writeValue(value: any): void {
    console.log(value, 'writeValue' + this.el.nativeElement.name);
    if (this.instance) {
      this.instance.set(value);
    } else {
      // autonumeric hasn't been initialised yet, stor``  e the value for later use
      this.initialValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.instance) {
      if (this.isDisabled) {
        this.renderer.setProperty(this.instance.domElement, 'disabled', 'disabled');
      } else {
        this.renderer.removeAttribute(this.instance.domElement, 'disabled');
      }
    }
  }
  ngOnInit(): void {

    this.instance = new AutoNumeric(this.el.nativeElement,this.options);
    /*  this.ctl.valueChanges.pipe(first()).subscribe(s => {
       console.log(s, 'valueChanges');
     }); */


  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes, 'ngOnChanges');
  }
  ngAfterViewInit(): void {
    this.unsubscribeFormat = this.renderer.listen(this.el.nativeElement, 'autoNumeric:formatted', ($event) => {
      // console.log($event.detail, 'autoNumeric:formatted');
      this.formatted.emit($event);
    });
    this.unsubscribeRawValueModified = this.renderer.listen(this.el.nativeElement, 'autoNumeric:rawValueModified', ($event) => {
      // console.log($event.detail.newRawValue, 'autoNumeric:rawValueModified');
      this.onChangeFn($event.detail.newRawValue);
      this.rawValueModified.emit($event);
    });
  }
}
