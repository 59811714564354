<div class="row">
  <div class="col-md-3">
    <button (click)="fileInput.click()" class="btn btn-default"><i class="bi bi-upload "></i> Upload Photos</button>
    <span
      style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
      <input type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple (change)="onFileSelected()"
        accept="image/x-png,image/gif,image/jpeg" />
    </span>
  </div>
  <div class="col-md-9 text-right">
    <p>Queue length: {{ uploader?.queue?.length }}</p>
  </div>
</div>
<div class="row mt-2">
  <div class="col-md-12">

    <div class="row" style="max-height: 500px;overflow-y: scroll;">
      <div class="thumbnail col-auto" *ngFor="let item of uploader.queue">
        <img  height="140" appThumbnail [image]="item?._file" />
        <p class="small text-muted mb-0">
          Size: {{ item?.file?.size/1024/1024 | number:'.2' }} MB

          <span *ngIf="item.isSuccess"><i class="bi bi-check"></i></span>
          <span *ngIf="item.isCancel"><i class="bi-door-closed "></i></span>
          <span *ngIf="item.isError"><i class="bi bi-trash-fill"></i></span>

        </p>
        <div class="progress" style="margin-bottom: 0;">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }">
          </div>
        </div>
        <button type="button" class="btn btn-danger btn-sm mt-1" (click)="removeFile(item)" style="padding: 3px 5px;">
          <span class="bi bi-trash-fill"></span> Remove
        </button>
      </div>
      <div class="thumbnail col-auto" *ngFor="let item of photos | filter: filterPhotos">
        <div *ngIf="item.exist">
          <img [src]="geturi(item.id)" height="140" appThumbnail />
          <p class="mb-0">
            <button type="button" class="btn btn-danger btn-block btn-sm mt-1" (click)="remove(item)"
              style="padding: 2px 5px;">
              <span class="bi bi-trash"></span> Remove
            </button>
          </p>
        </div>
      </div>
    </div>


  </div>


</div>