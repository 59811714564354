import { Component, OnInit } from '@angular/core';
import { BaseModal } from '../base-modal';



import { first } from 'rxjs/operators';

import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';

import { Guid } from 'guid-typescript';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VehicleService } from 'src/app/domain/services/vehicle.service';
import { FormService } from 'src/app/domain/services/form.service';
import { MasterDataService } from 'src/app/domain/services/master-data.service';
import { AutoPartComputation } from 'src/app/domain/models/auto-part-computation';
import { ParserUtil } from '../../util/parser.util';
import { CustomValidators } from '../../validators/custom-validators';
import { PartFilter } from 'src/app/domain/models/PartFilter';
import { GarageOption } from 'src/app/domain/models/garage-option';
import { AutoPart } from 'src/app/domain/models/auto-part';
import { BasicDTO } from 'src/app/domain/models/basic-dto';
import { ConditionType } from 'src/app/domain/models/condition-type';
import { QuotationAutopartItem } from 'src/app/domain/models/quotation-autopart-item';
import { Tax } from 'src/app/domain/models/tax';

@Component({
  selector: 'app-add-autopart',
  templateUrl: './add-autopart.component.html',
  styleUrls: ['./add-autopart.component.css']
})
export class AddAutopartComponent extends BaseModal implements OnInit {
  hasDiscount: boolean=false;
  currentPage = 1;
  page = 1;
  pageSize = 10;
  maxSize=10
  totalItems = 0;
  modelId: string | any;
  categoryId: string | any;
  option: GarageOption | any;
  searchText: string | any;
  categories: BasicDTO[]=[];
  autoParts: AutoPart[]=[];
  form!: FormGroup;
  conditionTypes: ConditionType[]=[];
  taxes: Tax[]=[];
  computation:  AutoPartComputation = { total: 0, discount: 0, markup: 0, vatAmount: 0};
  constructor(
    activeModal: NgbActiveModal ,
    private fb: FormBuilder,
    private vehicleService: VehicleService,
    private formService: FormService,
    private masterDataService: MasterDataService,
  ) {
    super(activeModal);
  }
  override ngOnInit() {
    this.createForm();
    this.vehicleService.getCategories(this.modelId).pipe(first()).subscribe(data => this.categories = data);
    this.masterDataService.getConditionTypes().pipe(first()).subscribe(data => this.conditionTypes = data);
    this.masterDataService.getTaxes().pipe(first()).subscribe(data => this.taxes = data);
    this.loadAutoparts();



  }
  calculateAmount(): void {

    const unitprice = ParserUtil.toDecimal(this.f['unitPrice'].value);
    const discountRate = ParserUtil.toDecimal(this.f['discountRate'].value);
    const quantity = ParserUtil.toDecimal(this.f['quantity'].value);
    const condition = this.conditionTypes.find(s => s.id === this.f['conditionId'].value);
    const tax = this.taxes.find(s => s.id === this.f['taxId'].value);
    if (condition) {
      const hasMarkUp = condition.hasMarkUp;
      let markup = 0;
      if (hasMarkUp) {
          markup = unitprice * (this.option.markupRate / 100);
      }
      // this.computation.markup = markup;
      this.f['markUp'].setValue(markup);
      let estimate = unitprice + markup;
      let discount = 0;
      if (this.hasDiscount) {
          discount = estimate * (discountRate / 100);
          estimate = estimate - discount;
      }
      // this.computation.discount = discount;
      this.f['discountAmount'].setValue(discount);
      let vatRate = 0;
      if (tax != null) {
          vatRate = tax.rate;
      }
      const vatamount = vatRate * estimate * quantity;
      this.f['vatAmount'].setValue(vatamount);
      // this.computation.vatAmount = vatamount;
       const total = (estimate * quantity) + vatamount;
      this.f['total'].setValue(total);
    }


  }
  createForm() {
   this.form = this.fb.group({
    id: [''],
    partId: ['', Validators.required],
    partName: ['', Validators.required],
    partNumber: ['', Validators.required],
    quantity: ['1', [Validators.required, CustomValidators.number]],
    unitPrice: ['0', [Validators.required, CustomValidators.amount]],
    total: ['0', ],
    conditionName: [''],
    conditionId: ['', Validators.required],
    vatAmount: ['0'],
    markUp: ['0'],
    discountAmount: ['0'],
    discountRate: ['0', CustomValidators.percent],
    taxId: [ this.option.defaultTaxId, Validators.required],
   });
   this.f['unitPrice'].valueChanges.subscribe( val => this.calculateAmount());
   this.f['taxId'].valueChanges.subscribe( val => this.calculateAmount());
   this.f['quantity'].valueChanges.subscribe( val => this.calculateAmount());
   this.f['conditionId'].valueChanges.subscribe( val => this.calculateAmount());
   this.f['discountRate'].valueChanges.subscribe( val => this.calculateAmount());
  }
  get f() {
    return this.form.controls;
  }
  public invalid(contol: Object): boolean {
    return  this.formService.invalid(contol);
  }
  categoryChanged() {
    this.currentPage = 1;
    this.loadAutoparts();
  }
  public pageChanged(event: any): void {
    debugger
    this.page = event;
    this.loadAutoparts();
  }
  loadAutoparts() {

    const filter: PartFilter = {
      categoryId: this.categoryId,
      modelId: this.modelId,
      page: this.page,
      pageSize: this.pageSize,
      search: this.searchText,
    };
    this.vehicleService.getAutoparts(filter).pipe(first()).subscribe(data => {
      this.totalItems = data.recordCount;
      this.autoParts = data.data;
    });

  }
  selectAutoPart(part: AutoPart) {
    this.createForm();
    const model = {} as QuotationAutopartItem;
    model.partId = part.id;
    model.partName = part.name;
    model.partNumber =  part.partNumber;
    this.form.patchValue(model);

  }
  search() {
    this.page = 1;
    this.currentPage = 1;
    if (this.searchText) {
      this.loadAutoparts();
    }
  }
  reset() {
   this.page = 1;
   this.currentPage = 1;
    this.searchText = '';
    this.loadAutoparts();
  }
  addAutopart() {
    if (this.formService.validateForm(this.form)) {
      const model = Object.assign({} , this.form.value) as QuotationAutopartItem;
      model.quantity = ParserUtil.toDecimal(model.quantity);
      model.unitPrice = ParserUtil.toDecimal(model.unitPrice);
      model.discountRate = ParserUtil.toDecimal(model.discountRate);
      const vat = this.taxes.find(s => s.id === model.taxId);
      model.vatCode = 'N';
      let vatRate = 0;
      if (vat != null) {
          vatRate = vat.rate;
          model.vatCode = vat.code;
      }

      let hasMarkUp = false;
      const condition = this.conditionTypes.find(s => s.id === model.conditionId);
      if (condition != null) {
        model.conditionName  = condition.name;
          hasMarkUp = condition.hasMarkUp;
      }

      let markup = 0;
      if (hasMarkUp) {
          markup = model.unitPrice * (this.option.markupRate / 100);
      }
      let estimate = model.unitPrice + markup;
      let discount = 0;
      let discountRate = 0;
      if (this.hasDiscount) {
          discountRate = model.discountRate;
          discount = estimate * (discountRate / 100);
          estimate = estimate - discount;
      }

      const vatamount = vatRate * estimate * model.quantity;
      model.markUp = markup;
      model.total = (estimate * model.quantity) + vatamount;
      model.vatAmount = vatamount;
      model.discountAmount = discount;
      model.discountRate = discountRate;
      model.id = Guid.create().toString();
      this.submit(model);

    } else {
      this.formService.findInvalidControls(this.form);
    }
  }
  conditionChanged() {
    this.hasDiscount = false;
    const model = Object.assign({}, this.form.value) as QuotationAutopartItem;
    const condition = this.conditionTypes.find(f => f.id === model.conditionId);
    if (condition && condition.hasDiscount) {
      this.hasDiscount = true;

    } else if (condition && !condition.hasDiscount) {
      model.discountRate = 0;
      this.form.patchValue(model);
    }
  }
  resetForm() {
    this.createForm();
  }

}
