import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConditionType } from '../models/condition-type';
import { OtherCostType } from '../models/other-cost-type';
import { RepairItemStatus } from '../models/repair-item-status';
import { Tax } from '../models/tax';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) { }
  getConditionTypes() {
    return this.http.get<ConditionType[]>(`${this.baseUrl}api/v1/masterdata/conditionTypes`);
  }
  getTaxes() {
    return this.http.get<Tax[]>(`${this.baseUrl}api/v1/masterdata/taxes`);
  }
  getOtherCostTypes() {
    return this.http.get<OtherCostType[]>(`${this.baseUrl}api/v1/masterdata/othercostTypes`);
  }
  getRepairItemStatus() {
    return this.http.get<RepairItemStatus[]>(`${this.baseUrl}api/v1/masterdata/repairItemstatus`);
  }

}
