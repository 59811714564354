import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Envelope } from '../models/envelope';
import { PhotoUploadItem } from '../models/photo-upload-item';


@Injectable({
  providedIn: 'root'
})
export class PhotoUploadService {

  constructor(
    private http: HttpClient,  @Inject('BASE_URL') private baseUrl: string,
  ) { }

  getPhotos(refId: string): Observable<Envelope<PhotoUploadItem>> {
    return this.http.get<Envelope<PhotoUploadItem>>(`${this.baseUrl}api/v1/photos/GetUploads/${refId}`);
  }
  remove(model: PhotoUploadItem) {
    return this.http.post(`${this.baseUrl}api/v1/photos/remove`, model);
  }
}
