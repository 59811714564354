import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';

import { FileUploader, FileItem } from 'ng2-file-upload';
import { DocumentUploadType } from 'src/app/domain/models/document-upload-type';
import { DocumentUploadTypeItem } from 'src/app/domain/models/document-upload-type-item';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-document-upload-item',
  templateUrl: './document-upload-item.component.html',
  styleUrls: ['./document-upload-item.component.css']
})
export class DocumentUploadItemComponent implements OnInit {
  @Input() doc: DocumentUploadType;
  @Input() refId: string;
  @Output() documentUploadTypeChangedEvent = new EventEmitter();
  allowedMimeTypes:string='';
  constructor(
    private authService: AuthService,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  uri = `${this.baseUrl}api/v1/documents/upload`;
  public uploader!: FileUploader
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;

  ngOnInit() {

     this.allowedMimeTypes=this.doc.mimeTypes.map(s=>s.type).join(",");
     var uploaderMimeType=this.doc.mimeTypes.map(s=>s.type)
     this.uploader=new FileUploader({
      url: this.uri,
      isHTML5: true,
      authToken: `Bearer ${this.authService.getUserToken()}`,
      allowedMimeType:uploaderMimeType.length>0?uploaderMimeType:null
    });

    console.log("allowedMimeType",this.uploader.options.allowedMimeType);
    this.uploader.options. additionalParameter = { refId: this.refId};
    this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: any) => {
      const data: DocumentUploadTypeItem = JSON.parse(response);
      const file = this.doc.items.find(s => s.fileName === data.fileName);
      if (!file) {
         this.doc.items.push(data);
      } else {
        file.id = data.id;
      }
      this.documentUploadTypeChangedEvent.emit();
    };
    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      const exist = this.uploader.queue.find(s => s.file.name === fileItem.file.name && s.isUploaded);
      if (exist) {
        fileItem.remove();
      }
    };
    this.uploader.onWhenAddingFileFailed = (fileItem, filter) => {
      console.log(filter);
      if (filter.name = "mimeType") {
        var message= this.doc.mimeTypes.map(s=>s.name).join(',')
        alert(`Uploaded file types is not supported for (${this.doc.name.toUpperCase()}), these are the supported file types : ${message}`);
      }
    }
  }
  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  onFileSelected() {
    console.log(`ID: ${this.refId}`);

    this.uploader.uploadAll();
  }
  removeFile(fileItem: FileItem) {
    fileItem.remove();
     this.doc.items = this.doc.items.filter(f => f.fileName !== fileItem.file.name);
     this.documentUploadTypeChangedEvent.emit();
  }
  itemAlreadyUploaded():  DocumentUploadTypeItem[] {
    const files = this.uploader.queue.map(s => s.file.name);
    return this.doc.items.filter(s => !files.includes(s.fileName));
  }
  removeAlreadyUploadedFile(item: DocumentUploadTypeItem) {

     this.doc.items = this.doc.items.filter(f => f.fileName !== item.fileName);
     this.documentUploadTypeChangedEvent.emit();
  }
}
