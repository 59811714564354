<button (click)="fileInput.click()" class="btn btn-default text-uppercase text-left">{{doc.no}}. upload {{doc.name}} <i
    [ngStyle]="{ color: doc.mandatory ? 'red' : 'transparent' }" class="bi bi-star-fill"
    style="color: rgb(255, 0, 0);"></i></button>
<span
  style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0">
  <input type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple (change)="onFileSelected()"
    [accept]="allowedMimeTypes" />
</span>
<div *ngFor="let item of uploader.queue" >
  <span class="text-uppercase ms-4 ">{{item.file.name}}
    <button type="button" class="btn btn-danger btn-sm mt-1 mb-1 " (click)="removeFile(item)" style="padding: 3px 5px;">
      <span class="bi bi-trash"></span> Remove
    </button>
  </span>
  <div class="progress " style="margin-bottom: 0;">
    <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }">
    </div>
  </div>
</div>

<div *ngFor="let item of itemAlreadyUploaded()" class="px-2">
    <span class="text-uppercase ms-4 "><strong>{{item.fileName}}</strong>
      <button type="button" class="btn btn-danger btn-xs mt-1 mb-1 ms-4 py-1 " (click)="removeAlreadyUploadedFile(item)" >
        <span class="bi bi-trash"></span> Remove
      </button>
    </span>

  </div>