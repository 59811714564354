import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logo',
  template:`<img height="50" src="assets/images/logo.png" alt="logo">`,
  //template:`{{clientName}}<span> {{appName}}</span>`,
})
export class LogoComponent implements OnInit {
  clientName="Expaq"
  appName="garage"
  constructor() { }

  ngOnInit(): void {
  }

}
