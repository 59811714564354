import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Envelope } from '../models/envelope';
import { DocumentUploadType } from '../models/document-upload-type';

@Injectable({
  providedIn: 'root'
})
export class DocumentUploadService {

  constructor(
   private http: HttpClient,
   @Inject('BASE_URL') private baseUrl: string,
  ) { }

  getDocuments(operationTypeId: number, refId: string): Observable<Envelope<DocumentUploadType>> {
    return this.http.get<Envelope<DocumentUploadType>>(`${this.baseUrl}api/v1/documents/GetUploads/${operationTypeId}/${refId}`);
  }
}
