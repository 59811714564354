import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { first } from 'rxjs/operators';
import { DocumentUploadType } from 'src/app/domain/models/document-upload-type';
import { DocumentUploadService } from 'src/app/domain/services/document-upload.service';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit {
  @Input() refId: string;
  @Input() operationTypeId: number;
  @Output() documentsChangedEvent = new EventEmitter<DocumentUploadType[]>();

  documents: DocumentUploadType[];
  constructor(
    private documentService: DocumentUploadService
  ) { }

  ngOnInit() {
    this.documentService.getDocuments(this.operationTypeId, this.refId).pipe(first()).subscribe(data => {
      this.documents = data.data;
      this.documentsChangedEvent.emit(this.documents);
   });

  }
  documentTypeUpdated() {
    this.documentsChangedEvent.emit(this.documents);
  }

}
