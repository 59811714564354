import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { NavService } from 'src/app/domain/services/nav.service';
import { User } from 'src/app/shared/models/user.models';

import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  user!: User;
  unsubscribe: Subject<Object| any> = new Subject<Object>();
  searchText: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private authService: AuthService,
    private navService: NavService,
  ) { }

  ngOnInit(): void {
    this.authService.currentUser
      .pipe(
        takeUntil(this.unsubscribe),
        filter((user) => user != null)
      )
      .subscribe((user) => {
        this.user=user;
        //console.log('User info => ', user);
      });
  }
  ngOnDestroy() {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
   }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {

    this.authService.logout();
    this.router.navigate(['/auth/login']);
    //e.preventDefault();
    //localStorage.removeItem('isLoggedin');

    /* if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    } */
  }

  search() {
    debugger
    this.navService.setSearch(this.searchText);
  }

}
