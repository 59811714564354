import { Component, OnInit } from '@angular/core';
import { BaseModal } from '../base-modal';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Guid } from 'guid-typescript';


import { first, filter } from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from 'src/app/domain/services/form.service';
import { MasterDataService } from 'src/app/domain/services/master-data.service';
import { GarageOption } from 'src/app/domain/models/garage-option';
import { OtherCostType } from 'src/app/domain/models/other-cost-type';
import { QuotationOtherCostItem } from 'src/app/domain/models/quotation-other-cost-item';
import { Tax } from 'src/app/domain/models/tax';
import { ParserUtil } from '../../util/parser.util';
import { CustomValidators } from '../../validators/custom-validators';

@Component({
  selector: 'app-add-other-cost',
  templateUrl: './add-other-cost.component.html',
  styleUrls: ['./add-other-cost.component.css']
})
export class AddOtherCostComponent extends BaseModal implements OnInit {
  option: GarageOption={
    defaultTaxId: '',
    defaultCompanyId: '',
    markupRate: 0,
    discountRate: 0,
    id: ''
  };
  form!: FormGroup<any>;
  otherCostTypes: OtherCostType[]=[];
  taxes: Tax[]=[];
  descriptionRequired = false;
  total = 0 ;

  constructor(
    activeModal: NgbActiveModal ,
    private fb: FormBuilder,
    private formService: FormService,
    private masterDataService: MasterDataService
  ) {
    super(activeModal);
  }


  override ngOnInit() {
    this.createForm();
    this.masterDataService.getOtherCostTypes().pipe(first()).subscribe(data => this.otherCostTypes = data);
    this.masterDataService.getTaxes().pipe(first()).subscribe(data => this.taxes = data);
    this.f['amount'].valueChanges.subscribe( val => this.calculateTotal());
    this.f['taxId'].valueChanges.subscribe( val => this.calculateTotal());
  }
  calculateTotal() {

    this.total = 0;
    let vatRate = 0;
    const vat = this.taxes.find(s => s.id === this.f['taxId'].value) ;
    if (vat) {  vatRate = vat.rate; }
     const c = this.f['amount'];
     const model = Object.assign({}, this.form.value);
    const amount = ParserUtil.toDecimal(c.value);
    if (amount) {
      this.total = amount + (amount * vatRate );
      this.f['total'].setValue(this.total);
    }

  }
  createForm() {
     const id = Guid.create().toString();
     this.form = this.fb.group({
       id: [id],
       chargeTypeId: ['', Validators.required],
       amount: [0, [Validators.required, CustomValidators.amount]],
       description: [''],
       vat: [0],
       total: [0],
       taxId: [this.option.defaultTaxId, Validators.required]
     });
  }
  get f()  {
    return this.form.controls;
  }
  invalid(contol: Object | any): boolean {
   return this.formService.invalid(contol);
  }
  add() {

    if (this.formService.validateForm(this.form)) {

      const model = Object.assign({}, this.form.value) as QuotationOtherCostItem;
      model.amount = ParserUtil.toDecimal(model.amount);
      let vatRate = 0;
     const vat = this.taxes.find(s => s.id === model.taxId) ;
    if (vat) {  vatRate = vat.rate; }
      model.vat = vatRate *  model.amount;
      model.total = model.amount + model.vat;
      const costType = this.otherCostTypes.find(s => s.id === model.chargeTypeId) ;
      if (costType) {  model.chargeName = costType.name; }
      this.submit(model);
    } else {
       this.formService.findInvalidControls(this.form);
    }
  }
  chargeTypeChanged() {
    this.descriptionRequired = false;
    const model = Object.assign({}, this.form.value) as QuotationOtherCostItem;
    const type = this.otherCostTypes.find(s => s.id === model.chargeTypeId);
    const control = this.form.controls['description'];
    if (type && type.requireDescription) {
      this.descriptionRequired = true;
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();

    } else {
      control.clearValidators();
      control.updateValueAndValidity();
    }
  }
}
