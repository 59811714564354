import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { AutoPart } from '../models/auto-part';
import { BasicDTO } from '../models/basic-dto';
import { Envelope } from '../models/envelope';
import { PartFilter } from '../models/PartFilter';


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
  ) { }

  getMakes() {
    return this.http.get<BasicDTO[]>(`${this.baseUrl}api/v1/vehicle/makes`);
  }
  getMakeTypes(makeId: string) {
    return this.http.get<BasicDTO[]>(`${this.baseUrl}api/v1/vehicle/maketypes/${makeId}`);
  }
  getMakeModels(typeId: string) {
    return this.http.get<BasicDTO[]>(`${this.baseUrl}api/v1/vehicle/makemodels/${typeId}`);
  }
  getYears() {
    return this.http.get<BasicDTO[]>(`${this.baseUrl}api/v1/vehicle/years`);
  }
  getCategories(modelId: string) {
    return this.http.get<BasicDTO[]>(`${this.baseUrl}api/v1/vehicle/categories/${modelId}`);
  }
  getAutoparts(filter: PartFilter): Observable<Envelope<AutoPart>> {
    return this.http.post<Envelope<AutoPart>>(`${this.baseUrl}api/v1/vehicle/parts`, filter);
  }

}
