
<div class="modal-header">
  <h4 class="modal-title text-uppercase" id="modal-basic-title ">Add  other cost</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
      <div class="row">
          <div class="form-group col-md-12">
            <label for="chargeTypeId">Service :</label>
            <select (change)="chargeTypeChanged()" class="form-select" id="chargeTypeId" formControlName="chargeTypeId" [ngClass]="{ 'is-invalid': invalid(f.chargeTypeId)  }">
              <option value="">-------</option>
              <option *ngFor="let item of otherCostTypes" [value]="item.id">{{item.name}}</option>
            </select>
            <div *ngIf="invalid(f['chargeTypeId'])" class="invalid-feedback">
              <div *ngIf="f['chargeTypeId'].errors?.required">Service is required</div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="descriptionRequired">
            <div class="form-group col-md-12">
              <label for="description"> Description</label>
              <textarea type="text" class="form-control" formControlName="description"
                [ngClass]="{ 'is-invalid': invalid(f.description)  }" placeholder="Description" ></textarea>
              <div *ngIf="invalid(f['description'])" class="invalid-feedback">
                <div *ngIf="f['description'].errors?.required"> Description is required</div>
              </div>
            </div>
          </div>
    <div class="row">
      <div class="form-group col-md-4">
        <label for="amount"> Amount</label>
        <input type="text" class="form-control" formControlName="amount" appNumberInput
          [ngClass]="{ 'is-invalid': invalid(f.amount)  }" placeholder="amount" />
        <div *ngIf="invalid(f['amount'])" class="invalid-feedback">
          <div *ngIf="f['amount'].errors?.required"> Amount is required</div>
          <div *ngIf="f['amount'].errors?.amount">This should be a valid amount, up to 2 decimal places</div>
        </div>
      </div>
      <div class="form-group col-md-4">
          <label for="taxId">Tax:</label>
          <select class="form-select" id="taxId" formControlName="taxId" [ngClass]="{ 'is-invalid': invalid(f.taxId)  }">
            <option value="">-------</option>
            <option *ngFor="let item of taxes" [value]="item.id">{{item.description}}</option>
          </select>
          <div *ngIf="invalid(f['taxId'])" class="invalid-feedback">
            <div *ngIf="f['taxId'].errors?.required">Tax is required</div>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label for="total">Total:</label>
          <input type="text" id="total" class="form-control"  formControlName="total" appNumberInput  [attr.disabled] ="true"/>

        </div>
    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="add()">Add or Update</button>
  <button type="button" class="btn btn-default" (click)="close()">Close</button>
</div>