import { Component, Inject, Input, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { config } from 'rxjs';
import { first } from 'rxjs/operators';
import { PhotoUploadItem } from 'src/app/domain/models/photo-upload-item';
import { PhotoUploadService } from 'src/app/domain/services/photo-upload.service';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.css']
})
export class PhotoViewerComponent implements OnInit {
  @Input() refId: string;
  photos: PhotoUploadItem[] = [];
  private _albums:any[] = [];
  constructor(
    private photoUploadSevice: PhotoUploadService,
    private _lightbox: Lightbox,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  ngOnInit(): void {
    console.log(this.refId, 'ref id');
    if (this.refId) {
      this.photoUploadSevice.getPhotos(this.refId).pipe(first()).subscribe(data => {
        this.photos = data.data;
        this.photos.forEach(photo => {
          const src = this.geturi(photo.id);
          const caption = 'Image';
          const thumb = this.geturi(photo.id);
          const album = {
            src: src,
            caption: caption,
            thumb: thumb
          };
          this._albums.push(album);
        });

      });
    }
  }
  public geturi(id:any): string {
    return `${this.baseUrl}api/v1/photos/view/${id}`;
  }

  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

}
