<div class="main-wrapper">

    <!-- partial:sidebar -->
    <app-sidebar *ngIf="menuItems && menuItems.length>0" [menuItems]="menuItems" ></app-sidebar>
    <!-- partial -->

    <div class="page-wrapper">

      <!-- partial:navbar -->
      <app-navbar></app-navbar>
      <!-- partial -->

      <div class="page-content">

        <!-- Spinner for lazyload modules -->
        <div *ngIf="isLoading" class="spinner-wrapper">
          <div class="spinner">Loading...</div>
        </div>
        <app-spinner></app-spinner>

        <div contentAnimate *ngIf="!isLoading">
          <router-outlet></router-outlet>
        </div>

      </div>

      <!-- partial:footer -->
      <app-footer></app-footer>
      <!-- partial -->

    </div>


  </div>