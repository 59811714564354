<div class="row pt-2 pb-2">
  <div class="col-sm-9">
    <h4 class="page-title">Notifications</h4>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card">
      <div class="card-header text-uppercase"> Alerts</div>
      <div class="card-body">
        <div class="alert alert-danger alert-dismissible" role="alert">
          <div class="alert-icon">
            <i class="icon-close"></i>
          </div>
          <div class="alert-message">
            <span><strong>Error!</strong> {{message}}</span>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>