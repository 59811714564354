<!-- <div class="modal-header">
  <h6 class="modal-title pull-left text-uppercase"> Confirm</h6>
  <button type="button" class="close pull-right" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Confirm</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">

   <p class="text-center" style="color: black">
      <span>{{messageText}}</span>
   </p>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary " (click)="ok()">OK</button>
  <button type="button" class="btn btn-danger " (click)="cancel()">Cancel</button>
</div>