import { Component, OnInit } from '@angular/core';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { first } from 'rxjs';
import { MenuItem } from 'src/app/shared/models/menu.model';
import { MenuService } from 'src/app/shared/services/menu.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  isLoading: boolean=false;
  menuItems: MenuItem[]=[];
  constructor(private router: Router,private menuService:MenuService) {
    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });


  }

  ngOnInit(): void {
     this.menuService.getMenu()
    .pipe(first())
    .subscribe({
      next:(_)=>{
       
        
        this.menuItems=_
      }
    })
  }

}
