import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  private searchSubject: BehaviorSubject<string | any>;
  public search: Observable<string | any>;
  constructor() {
    this.searchSubject = new BehaviorSubject<string | any>(null);
    this.search = this.searchSubject.asObservable().pipe(filter(s => s !== null));
   }
   setSearch(searchText: string) {
      this.searchSubject.next(searchText);
   }


}
