
<div class="modal-header">
  <h4 class="modal-title text-uppercase" id="modal-basic-title">Add Autopart</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-8">
      <div class="form-group mb-2">
        <label for="companyId">Category:</label>
        <select class="form-select" id="category" [(ngModel)]="categoryId" (change)="categoryChanged()">
          <option value="">-------</option>
          <option *ngFor="let item of categories" [value]="item.id">{{item.name}}</option>
        </select>
      </div>
      <div class="form-group  mb-2">
       
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="searchText" />
            <button class="btn btn-secondary" type="button"  (click)="search()"><i class="bi bi-search"></i></button>
            <button class="btn btn-secondary" type="button" (click)="reset()"><i class="bi bi-x-circle"></i></button>
           
          </div>
        
      </div>
      <div class="table-responsive  mb-2">
        <table class="table table-sm table-bordered">
          <thead class="thead-secondary">
            <tr>
              <th>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of autoParts">
              <td> <a href="javascript:void()" (click)="selectAutoPart(item)">{{item.partNumber}}</a></td>
              <td> <a href="javascript:void()" (click)="selectAutoPart(item)">{{item.name}}</a></td>
            </tr>

          </tbody>
        </table>
        <div class="row mt-2">
          <div class="col-xs-12 col-12">
            <!-- <pagination class="pagination-outline-primary" [(ngModel)]="currentPage" [totalItems]="totalItems"
              (pageChanged)="pageChanged($event)" [boundaryLinks]="true" previousText="&lsaquo;" nextText="&rsaquo;"
              firstText="&laquo;" lastText="&raquo;"></pagination> -->
            <ngb-pagination (pageChange)="pageChanged($event)" [collectionSize]="totalItems" [pageSize]="pageSize"
              [(page)]="page" [maxSize]="maxSize" [boundaryLinks]="true"></ngb-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <form [formGroup]="form">

        <div class="row">
          <div class="form-group col-md-12">
            <label for="partNumber"> Part Number</label>
            <input [readOnly]="true" type="text" class="form-control" formControlName="partNumber"
              [ngClass]="{ 'is-invalid': invalid(f.partNumber)  }" placeholder="Select Autopart" />
            <div *ngIf="invalid(f['partNumber'])" class="invalid-feedback">
              <div *ngIf="f['partNumber'].errors?.required"> Part Number is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="partName">Part Name:</label>
            <input [readOnly]="true" type="text" class="form-control" formControlName="partName"
              [ngClass]="{ 'is-invalid': invalid(f.partName)  }" placeholder="Select Autopart" />
            <div *ngIf="invalid(f['partName'])" class="invalid-feedback">
              <div *ngIf="f['partName'].errors?.required">Partname is required</div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-12">
            <label for="conditionId">Condition:</label>
            <select class="form-select" id="conditionId" formControlName="conditionId" (change)="conditionChanged()"
              [ngClass]="{ 'is-invalid': invalid(f.conditionId)  }">
              <option value="">-------</option>
              <option *ngFor="let item of conditionTypes" [value]="item.id">{{item.name}}</option>
            </select>
            <div *ngIf="invalid(f['conditionId'])" class="invalid-feedback">
              <div *ngIf="f['conditionId'].errors?.required">Condition is required</div>
            </div>
          </div>
        </div>
        <!--  [value]="f['unitPrice'].value | numeral" -->
        <div class="row">
          <div class="form-group col-md-6">
            <label for="quantity">quantity:</label>
            <input type="text" class="form-control" formControlName="quantity"
              [ngClass]="{ 'is-invalid': invalid(f.quantity)  }" placeholder="quantity" />
            <div *ngIf="invalid(f['quantity'])" class="invalid-feedback">
              <div *ngIf="f['quantity'].errors?.required">quantity is required</div>
              <div *ngIf="f['quantity'].errors?.number">This should be in number 0-999,up to 2 decimal places</div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="unitPrice">Price(Exl markup):</label>
            <input type="text" class="form-control" formControlName="unitPrice" appNumberInput
              [ngClass]="{ 'is-invalid': invalid(f.unitPrice)  }" placeholder="unitPrice" />
            <div *ngIf="invalid(f['unitPrice'])" class="invalid-feedback">
              <div *ngIf="f['unitPrice'].errors?.required">Unit price is required</div>
              <div *ngIf="f['unitPrice'].errors?.amount">This should be in amount,up to 2 decimal places</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6" *ngIf="hasDiscount">
            <label for="discountRate">discountRate:</label>
            <input type="text" class="form-control" formControlName="discountRate"
              [ngClass]="{ 'is-invalid': invalid(f.discountRate)  }" placeholder="discountRate" />
            <div *ngIf="invalid(f['discountRate'])" class="invalid-feedback">
              <div *ngIf="f['discountRate'].errors?.required">DiscountRate is required</div>
              <div *ngIf="f['discountRate'].errors?.percent">This should be in percentage and not more than 100%,up to 2
                decimal places</div>
            </div>
          </div>
          <div class="form-group col-md-6">
            <label for="taxId">Tax:</label>
            <select class="form-select" id="vatCode" formControlName="taxId"
              [ngClass]="{ 'is-invalid': invalid(f.taxId)  }">
              <option value="">-------</option>
              <option *ngFor="let item of taxes" [value]="item.id">{{item.description}}</option>
            </select>
            <div *ngIf="invalid(f['taxId'])" class="invalid-feedback">
              <div *ngIf="f['taxId'].errors?.required">Tax is required</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="markup">Mark Up:</label>
            <input type="text" id="markup" class="form-control" formControlName="markUp" appNumberInput
              [attr.disabled]="true" />
          </div>
          <div class="form-group col-md-6">
            <label for="discount">Discount:</label>
            <input type="text" id="discount" class="form-control" formControlName="discountAmount" appNumberInput
              [attr.disabled]="true" />
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="vatAmount">VAT:</label>
            <input type="text" id="vatAmount" class="form-control" formControlName="vatAmount" appNumberInput
              [attr.disabled]="true" />
          </div>
          <div class="form-group col-md-6">
            <label for="total">Total:</label>
            <input type="text" id="total" class="form-control" formControlName="total" appNumberInput
              [attr.disabled]="true" />
          </div>
        </div>
        <div class="row mt-2">
          <div class=" col-md-13">
            <button type="button" class="btn btn-primary" (click)="addAutopart() ">
              <i class="bi bi-plus-circle"></i> Add
            </button>
            <button type="button" class="btn btn-primary  ms-3" (click)="resetForm() ">
              <i class="bi bi-x-circle"></i> Reset
            </button>
          </div>
          
        </div>
      </form>
    </div>
  </div>


</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close()">Close</button>
</div>