import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { LogoComponent } from './components/logo/logo.component';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';
import { FeatherIconDirective } from './directives/feather-icon.directive';
import { NumberInputDirective } from './directives/number-input.directive';
import { ValidityStyleDirective } from './directives/validity-style.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAutopartComponent } from './modals/add-autopart/add-autopart.component';
import { AddOtherCostComponent } from './modals/add-other-cost/add-other-cost.component';
import { ConfirmBoxComponent } from './modals/confirm-box/confirm-box.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { PermissionComponent } from './components/permission/permission.component';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';
import { DocumentUploadItemComponent } from './components/document-upload-item/document-upload-item.component';
import { PhotoUploadComponent } from './components/photo-upload/photo-upload.component';
import { PhotoViewerComponent } from './components/photo-viewer/photo-viewer.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FilterPipePipe } from './pipes/filter-pipe.pipe';
import { ThumbnailDirective } from './directives/thumbnail.directive';



@NgModule({
  declarations: [
    SpinnerComponent,
    ErrorPageComponent,
    LogoComponent,
    ErrorDisplayComponent,
    FeatherIconDirective,
    NumberInputDirective,
    ValidityStyleDirective,
    AddAutopartComponent,
    AddOtherCostComponent,
    ConfirmBoxComponent,
    PermissionComponent,
    DocumentUploadComponent,
    DocumentUploadItemComponent,
    PhotoUploadComponent,
    PhotoViewerComponent,
    FilterPipePipe,
    ThumbnailDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbPaginationModule,
    FileUploadModule,
  ],
  exports:[
    SpinnerComponent,
    ErrorPageComponent,
    LogoComponent,
    ErrorDisplayComponent,
    FeatherIconDirective,
    NumberInputDirective,
    ValidityStyleDirective,
    AddAutopartComponent,
    AddOtherCostComponent,
    ConfirmBoxComponent,
    PermissionComponent,
    DocumentUploadComponent,
    DocumentUploadItemComponent,
    PhotoUploadComponent,
    PhotoViewerComponent,
    ThumbnailDirective
  ]
})
export class SharedModule { }
