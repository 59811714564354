import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';

import { first } from 'rxjs/operators';
import { PhotoUploadItem } from 'src/app/domain/models/photo-upload-item';
import { PhotoUploadService } from 'src/app/domain/services/photo-upload.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.css']
})
export class PhotoUploadComponent implements OnInit {
  @Output() sendUploadedPhotoEvent = new EventEmitter<PhotoUploadItem[]>();
  @Input() refId: string;
  photos: PhotoUploadItem[] = [];
  existingPhotos: PhotoUploadItem[] = [];
  uri = `${this.baseUrl}api/v1/photos/upload`;
  public uploader: FileUploader = new FileUploader({
    url: this.uri,
    isHTML5: true,
    allowedMimeType: ['image/png', 'image/gif', 'image/jpeg'],
    authToken: `Bearer ${this.authService.getUserToken()}`,

  });
  filterPhotos = { exist: true };

  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  constructor(
    private authService: AuthService,
    private photoUploadSevice: PhotoUploadService,
    @Inject('BASE_URL') private baseUrl: string,
  ) {

  }


  ngOnInit() {
    this.uploader.options. additionalParameter = { refId: this.refId};
    this.uploader.onCompleteItem = (item: any, response: string, status: number, headers: any) => {
      const data: PhotoUploadItem = JSON.parse(response);

      this.photos.push(data);
      this.sendUploadedPhotoEvent.emit(this.photos);
    };

    this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
      const exist = this.uploader.queue.find(s => s.file.name === fileItem.file.name && s.isUploaded);
      if (exist) {
        fileItem.remove();
      }
    };
    this.photoUploadSevice.getPhotos(this.refId).pipe(first()).subscribe(data => {
      this.photos = data.data;
      this.existingPhotos = this.photos;
      this.sendUploadedPhotoEvent.emit(this.photos);
    });

  }
  public geturi(id:any): string {
     return `${this.baseUrl}api/v1/photos/view/${id}`;
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }
  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  onFileSelected() {
    console.log(`ID: ${this.refId}`);
    this.uploader.uploadAll();
  }
  removeFile(fileItem: FileItem) {
    fileItem.remove();
    this.photos = this.photos.filter(f => f.fileName !== fileItem.file.name);
    this.sendUploadedPhotoEvent.emit(this.photos);
  }
  remove(item: PhotoUploadItem) {
    this.photos = this.photos.filter(f => f.id !== item.id);
    this.existingPhotos = this.photos;
    this.sendUploadedPhotoEvent.emit(this.photos);
    this.photoUploadSevice.remove(item).pipe(first()).subscribe(s => {});
  }
}
