import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { AppInfo } from 'src/app/shared/models/app-info.model';
import { AppInfoService } from 'src/app/shared/services/app-info.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  info: AppInfo;

  constructor(private appInfoService:AppInfoService) { }

  ngOnInit(): void {
    
    this.appInfoService.getInfo().pipe(first()).subscribe({
      next:(_)=> {
        this.info=_;
      }
    })
  }

}
