<nav class="navbar">
    <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
      <i class="feather icon-menu"></i>
    </a>
    <div class="navbar-content">
      <div class="d-flex justify-content-start ms-1"  >
        <img class=" bg-white p-1" src="/api/v1/resources/logo">
      </div>
      <form class="search-form ms-2" #f="ngForm" autocomplete="off">
        <div class="input-group">
          <div class="input-group-text">
            <i class="feather icon-search"></i>
          </div>
          <input type="text" class="form-control" name="searchInput"  id="navbarForm" placeholder="Search here..."  [(ngModel)]="searchText" (keydown.enter)="search()">
          
        </div>

      </form>


      <ul class="navbar-nav">


        <li class="nav-item nav-profile" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="profileDropdown">
            <img class="wd-30 ht-30 rounded-circle" src="assets/images/avatar-2.jpg" alt="profile">
          </a>
          <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
            <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
              <div class="mb-3">
                <img class="wd-80 ht-80 rounded-circle" src="assets/images/avatar-2.jpg" alt="">
              </div>
              <div class="text-center">
                <p class="tx-16 fw-bolder">{{user.firstName}} {{user.lastName}}</p>
                <p class="tx-12 text-muted">{{user.email}}</p>
              </div>
            </div>
            <ul class="list-unstyled p-1">
              <li class="dropdown-item py-2">
                <a routerLink="/general/profile" class="d-flex text-body ms-0">
                  <i class="feather icon-user me-2 icon-md"></i>
                  <span>Profile</span>
                </a>
              </li>
              <!-- <li class="dropdown-item py-2">
                <a href="" (click)="false" class="d-flex text-body ms-0">
                  <i class="feather icon-edit me-2 icon-md"></i>
                  <span>Edit Profile</span>
                </a>
              </li>
              <li class="dropdown-item py-2">
                <a href="" (click)="false" class="d-flex text-body ms-0">
                  <i class="feather icon-repeat me-2 icon-md"></i>
                  <span>Switch User</span>
                </a>
              </li> -->
              <li class="dropdown-item py-2">
                <a  (click)="onLogout($event)" class="d-flex text-body ms-0">
                  <i class="feather icon-log-out me-2 icon-md"></i>
                  <span>Log Out</span>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>